import React, { useContext, useState } from "react"
import supabase from "../services/supabase"
import { Box, Button, Text, Input, Label, Grid, Checkbox, Link } from "theme-ui"
import Select from "react-select"
import { i18nContext } from "../context/i18nContext"

const SignUpForm = () => {
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState("")
  const [company, setCompany] = useState("")
  const [jobTitle, setJobTitle] = useState("")
  const [fullName, setFullName] = useState("")
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [email, setEmail] = useState("")
  const [areaOfInterest, setAreaOfInterest] = useState([])
  const [password, setPassword] = useState("")
  const [error, showError] = useState(false)
  const [optionalConsent, setOptionalConsent] = useState(false)
  const t = useContext(i18nContext)

  const options = [
    { value: "biogas", label: t.formInputTexts.areaOfInterests.biogas },
    { value: "biomethane", label: t.formInputTexts.areaOfInterests.biomethane },
    {
      value: "cogeneration",
      label: t.formInputTexts.areaOfInterests.cogeneration,
    },
    { value: "netzero", label: t.formInputTexts.areaOfInterests.netZeroTech },
  ]

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)

    supabase.auth
      .signUp({
        email: email,
        password: password,
        options: {
          data: {
            full_name: fullName,
            country: country,
            company: company,
            job_title: jobTitle,
            area_of_interest: areaOfInterest,
            optionalConsent: optionalConsent,
          },
        },
      })
      .then(({ error }) => {
        if (error) {
          setLoading(false)
          showError(true)
          console.log("error", error)
        } else {
          console.log("logged in")
        }
      })
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box as="form" onSubmit={handleSubmit}>
          <Box sx={{ position: "relative" }}>
            <Label variant="label.kicker" htmlFor="full_name">
              {t.formInputTexts.fullName} *
            </Label>
            <Input
              onChange={e => setFullName(e.target.value)}
              type="text"
              name="full_name"
              id="full_name"
              value={fullName}
              autoComplete="name"
              required
              sx={{ mb: 4 }}
            />
          </Box>
          <Grid columns={[1, 1, 1, 3]} gap={[0, 0, 0, 4]}>
            <Box sx={{ position: "relative" }}>
              <Label variant="label.kicker" htmlFor="country">
                {t.formInputTexts.country} *
              </Label>
              <Input
                onChange={e => setCountry(e.target.value)}
                type="text"
                name="country"
                id="country"
                value={country}
                autoComplete="country-name"
                required
                sx={{ mb: 4 }}
              />
            </Box>
            <Box sx={{ position: "relative" }}>
              <Label variant="label.kicker" htmlFor="job_title">
                {t.formInputTexts.jobTitle}
              </Label>
              <Input
                onChange={e => setJobTitle(e.target.value)}
                type="text"
                name="job_title"
                id="job_title"
                value={jobTitle}
                autoComplete="organization-title"
                sx={{ mb: 4 }}
              />
            </Box>
            <Box sx={{ position: "relative" }}>
              <Label variant="label.kicker" htmlFor="company">
                {t.formInputTexts.company}
              </Label>
              <Input
                onChange={e => setCompany(e.target.value)}
                type="text"
                name="company"
                id="company"
                value={company}
                autoComplete="organization"
                sx={{ mb: 4 }}
              />
            </Box>
          </Grid>
          <Grid columns={[1, 1, 1]} gap={[0, 0, 4]}>
            <Box sx={{ position: "relative", mb: 4 }}>
              <Label
                variant="label.kicker"
                htmlFor="areaOfInterest"
                sx={{ zIndex: 3 }}
              >
                {t.formInputTexts.areaOfInterest} *
              </Label>
              <Select
                options={options}
                onChange={e => setAreaOfInterest(e)}
                placeholder={t.formInputTexts.areaOfInterestPlaceholder}
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 2,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#121212" : "#B2B2B2",
                    boxShadow: "none",
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "0 16px",
                  }),
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  border: "1px solid",
                  colors: {
                    ...theme.colors,
                    primary25: "#FBDF4B",
                    primary: "#121212",
                  },
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 53,
                  },
                })}
                isMulti
                name="areaOfInterest"
                id="areaOfInterest"
                required
              />
            </Box>
          </Grid>
          <Grid columns={[1, 1, 1, 2]} gap={[0, 0, 0, 4]}>
            <Box sx={{ position: "relative" }}>
              <Label variant="label.kicker" htmlFor="email">
                Email *
              </Label>
              <Input
                onChange={e => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                value={email}
                autoComplete="email"
                required
                sx={{ mb: 4 }}
              />
            </Box>
            <Box sx={{ position: "relative", mb: 4 }}>
              <Label variant="label.kicker" htmlFor="password">
                Password *
              </Label>
              <Input
                onChange={e => setPassword(e.target.value)}
                type="password"
                name="password"
                id="password"
                value={password}
                autoComplete="new-password"
                pattern=".{6,}" // 6 characters minimum
                required
              />
              <Text
                as="p"
                variant="caption"
                sx={{ fontWeight: "normal", mt: [2] }}
              >
                6 characters minimum{" "}
              </Text>
            </Box>
          </Grid>
          <Box sx={{ mb: [4] }}>
            <Label sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                required
                onChange={e => setPrivacyPolicy(e.target.checked)}
                checked={privacyPolicy}
              />
              <Text
                as="p"
                sx={{ fontWeight: "normal", fontSize: [0], color: "#666666" }}
              >
                {t.formInputTexts.privacyPolicy}{" "}
                <Link
                  href="https://www.iubenda.com/privacy-policy/62093833"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </Link>
              </Text>
            </Label>
          </Box>
          <Box sx={{ mb: [4] }}>
            <Label sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={e => setOptionalConsent(e.target.checked)}
                checked={optionalConsent}
              />
              <Text
                as="p"
                sx={{ fontWeight: "normal", fontSize: [0], color: "#666666" }}
              >
                {t.formInputTexts.optionalConsent}
              </Text>
            </Label>
          </Box>
          {error && (
            <Text as="p" sx={{ color: "red", mb: [3] }}>
              {t.signUpError}
            </Text>
          )}
          <Button sx={{ mt: [3] }}>{loading ? t.loading : t.signUp}</Button>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default SignUpForm
